import React from 'react';

const TermsOfService = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Terms of Service</h1>
      <p>
        Welcome to YouTube to MP3 Converter. By using this website, you agree to the following terms and conditions:
      </p>
      <ul>
        <li>Usage of this service is for personal purposes only.</li>
        <li>Ensure that you have the legal right to download content.</li>
        <li>We are not responsible for any misuse of this service.</li>
      </ul>
    </div>
  );
};

export default TermsOfService;