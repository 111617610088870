import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Privacy Policy</h1>
      <p>
        Your privacy is important to us. This policy outlines how we collect, use, and protect your information.
      </p>
      <h2>Information We Collect</h2>
      <p>We do not collect personal data from users. Any information provided is used solely for service functionality.</p>
      <h2>Cookies</h2>
      <p>This website may use cookies for basic functionality and to improve user experience.</p>
    </div>
  );
};

export default PrivacyPolicy;