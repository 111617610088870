import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import TermsOfService from './tos';
import PrivacyPolicy from './privacypolicy';
import './App.css';

const App = () => {
  const [youtubeLink, setYoutubeLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleConvert = async () => {
    if (!youtubeLink.trim()) {
      alert('Please enter a valid YouTube link.');
      return;
    }

    if (!recaptchaToken) {
      alert('Please complete the CAPTCHA.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await fetch('https://youtube-to-mp3-convert-b314493773e7.herokuapp.com/convert', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: youtubeLink, recaptchaToken }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to convert video.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'audio.mp3';
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Router>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* Header Section */}
        <header
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <h1>YouTube to MP3 Converter</h1>
          <Routes>
            <Route
              path="/"
              element={
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
                  <input
                    type="text"
                    placeholder="Enter YouTube link"
                    value={youtubeLink}
                    onChange={(e) => setYoutubeLink(e.target.value)}
                    style={{
                      padding: '10px',
                      fontSize: '1rem',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                      width: '300px',
                    }}
                  />
                  <ReCAPTCHA
                    sitekey="6LcpJ5YqAAAAAJxbMzFIoj-s6F-qvtNumlLt7QSY"
                    onChange={(token) => setRecaptchaToken(token)}
                    onExpired={() => setRecaptchaToken(null)}
                  />
                  <button
                    onClick={handleConvert}
                    disabled={isLoading}
                    style={{
                      padding: '10px 20px',
                      fontSize: '1rem',
                      borderRadius: '5px',
                      border: 'none',
                      backgroundColor: isLoading ? '#9ec6e8' : '#61dafb',
                      color: '#282c34',
                      cursor: isLoading ? 'not-allowed' : 'pointer',
                      transition: 'background-color 0.3s ease',
                    }}
                  >
                    {isLoading ? 'Converting...' : 'Convert to MP3'}
                  </button>
                  {errorMessage && <p style={{ color: 'red', fontSize: '0.9rem' }}>{errorMessage}</p>}
                </div>
              }
            />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </header>

        {/* Footer Section */}
        <footer
          style={{
            backgroundColor: '#20232a',
            color: '#61dafb',
            textAlign: 'center',
            padding: '10px',
            position: 'relative',
            bottom: 0,
            width: '100%',
          }}
        >
          <Link to="/terms-of-service" style={{ color: '#61dafb', textDecoration: 'none', margin: '0 10px' }}>
            Terms of Service
          </Link>
          |
          <Link to="/privacy-policy" style={{ color: '#61dafb', textDecoration: 'none', margin: '0 10px' }}>
            Privacy Policy
          </Link>
        </footer>
      </div>
    </Router>
  );
};

export default App;